.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .input-text, .output-text, .template-select, .submit-button {
    width: 90%;
    margin: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .input-text {
    height: 200px;
    resize: vertical;
  }
  

  .output-text {
    height: 600px;
    resize: vertical;
  }
  
  .submit-button {
    background-color: #007BFF;
    color: white;
    cursor: pointer;
    border: none;
  }
  